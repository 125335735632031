@import '../../custom.scss';

#skills-box {
  width: 90px;
  margin: 10px 15px;
}

#skills-img-box {
  width: 75px;
  height: 75px;
  position: relative;
  border-radius: 45px;
  transition: .5s;
}

#skills-img-box:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  background: #fdf5ab;
  border-radius: 40px 40px 8px 40px;
}

#skills-img {
  width: 55px;
  z-index: 2;
}

h5::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 4px;
  border-radius: 0 2px 2px 0;
  background-color: $primary;
  transition: .5s;
}

h5:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 50%;
  width: 0;
  height: 4px;
  border-radius: 2px 0 0 2px;
  background-color: $primary;
  transition: .5s;
}

#skills-box:hover>h5:after,
#skills-box:hover>h5:before {
  width: 35px;
}

#skills-box:hover #skills-img-box {
  transform: translateY(-10px);
}