@import '../../custom.scss';

header {
  height: 100px;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1110px;
    padding: 0 15px;

    #header-inner-header {
      z-index: 10;
      height: 100px;

      #header-logo a {
        font-weight: 700;
        font-size: 1rem;
        text-decoration: none;
        color: #eee;

        #logo {
          height: 50px;
          width: auto;
        }
      }

      #header-menu {
        #menu-btn {
          font-size: 1rem;
          mix-blend-mode: difference;
        }
      }
    }
  }
}