.icon-bar {
  font-size: 22px;
  margin: 2px 0;
  border-radius: 0px 50px 50px 0px;
  transform: translate(-120px, 0px);
  transition: 0.5s;

  .bar-title {
    text-decoration: none;

    #icon-img {
      margin-left: 20px;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      transition: 1s;
      line-height: 40px;

      img {
        height: 30px;
      }
    }
  }
}

.icon-bar:hover {
  transform: translate(0px, 0px);
  transition: 0.5s;

  #icon-img {
    transform: rotate(360deg);
    transition: 1s;
  }
}

@media (max-width:1450px) {
  .icon-bar {
    margin: 2px 0;
    border-radius: 0px 50px 50px 0px;
    transform: translate(-130px, 0px);

    .bar-title {
      #icon-img {
        width: 35px;
        height: 35px;
        margin-left: 10px;
        line-height: 35px;

        img {
          height: 25px;
        }
      }
    }
  }

  .icon-bar:hover {
    transform: translate(-130px, 0px);

    #icon-img {
      transform: rotate(360deg);
      transition: 1s;
    }
  }
}