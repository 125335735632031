@import '../../custom.scss';

#menu-hamburger-menu {
  display: none;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;

  #menu-secondary-background-color {
    background: $secondary;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  #menu-layer {
    height: 100%;
    overflow: hidden;

    #menu-ul {
      font-weight: 700;

      #menu-li {
        overflow: hidden;
        padding-right: 20px;

        a {
          color: #fff;
          text-decoration: none;
          transition: 0.5s;

          &:hover {
            color: $secondary;
            margin-left: 5%;
            transition: 0.5s;
          }
        }
      }
    }
  }
}

@media(max-width:1200px) {
  nav {
    margin-left: 3%;

    #menu-li {
      font-size: 5rem;
      transition: 0.5s;
    }
  }
}

@media(max-width: 992px) {
  #menu-li {
    font-size: 5rem;
    transition: 0.5s;
  }
}

@media(max-width: 768px) {
  #menu-wrapper {
    margin: 0 auto;
    transition: 0.5s;

    #menu-li {
      text-align: center;
      font-size: 4rem;
      transition: 0.5s;
    }
  }
}

@media(max-width: 576px) {
  #menu-li {
    font-size: 3rem;
    transition: 0.5s;
  }
}