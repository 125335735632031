@import '../../custom.scss';

#index-container {
	top: 0;
	left: 0;
	background-image: url('../../assets/images/index-cover.jpg');
	background-size: initial;
	background-repeat: no-repeat;
	animation-name: backgroundEffect;
	animation-duration: 30s;
	animation-iteration-count: infinite;
}

#index-title-1 {
	font-size: 5rem;
	font-weight: 400;
	transition: 0.5s;
}

#index-title-2 {
	font-size: 2.5rem;
	font-weight: 300;
	transition: 0.5s;
}


@media (max-width: 767px) {
	#index-title-1 {
		font-size: 3.5rem;
		transition: 0.5s;
	}

	#index-title-2 {
		font-size: 2rem;
		transition: 0.5s;
	}
}

@keyframes backgroundEffect {
	0% {
		background-position: 25% 0%;
	}

	25% {
		background-position: 35% 10%;
	}

	50% {
		background-position: 45% 20%;
	}

	75% {
		background-position: 35% 10%;
	}

	100% {
		background-position: 25% 0%;
	}
}