@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,600,800&display=swap");

$font-stack: "Poppins",
-apple-system,
BlinkMacSystemFont,
"Segoe UI",
Roboto,
Oxygen,
Ubuntu,
Cantarell,
"Open Sans",
"Helvetica Neue",
sans-serif;
$light-color: #f4f4f4;
$primary: #0f4c81;
$secondary: #FADE58;
$black: #191919;
$dark-grey: rgba(100, 100, 100, 0.5);

html,
.App {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: $font-stack;
}

@import '~bootstrap/scss/bootstrap.scss';