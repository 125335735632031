@import '../../custom.scss';

#project-card-mobile {
  height: 540px;
  background: $secondary;
  box-shadow: 10px 20px 20px rgba(0, 0, 0, 0.5);

  #project-banner-mobile {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    #project-banner-img-mobile {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.5s;
    }
  }

  #project-content-mobile {
    bottom: 30px;
    left: 6%;
    width: 88%;
    height: 200px;
    background: #fff;
    opacity: 0.9;
    transition: 0.5s;
    overflow: hidden;
    padding: 15px;

    #project-content-desc-mobile {
      transition: 0.5s;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: horizontal;
      overflow: hidden;
    }

    #project-tech-icons-mobile {

      #tech-icon-img-mobile {
        height: 45px;
      }

      #tech-icon-img-hidden-mobile {
        height: 45px;
        opacity: 0;
      }
    }

    #project-content-link-mobile {
      width: 120px;
    }
  }
}

#project-card-mobile:hover {
  #project-banner-img-mobile {
    opacity: 0;
  }

  #project-content-mobile {
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;

    #project-content-desc-mobile {
      opacity: 1;
      transition-delay: .5s;
    }
  }
}

#project-content-link-mobile:hover {
  color: #fff;
  background-color: $primary;
  transition: 0.5s;
}

@media (max-width:500px) {
  #project-card-mobile {
    height: 600px;
  }
}