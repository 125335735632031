@import "../../custom.scss";

#about-me-heading:after {
	content: "";
	position: absolute;
	bottom: 0;
	right: 50%;
	width: 90px;
	height: 4px;
	border-radius: 2px 0 0 2px;
	background-color: $secondary;
	transition: 0.5s;
}

#about-me-heading::before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 90px;
	height: 4px;
	border-radius: 0 2px 2px 0;
	background-color: $secondary;
	transition: 0.5s;
}

#about-btn-main {
	position: relative;
	width: 180px;
}

@media (max-width: 992px) {
	#btn-row {
		display: none;
	}
}
