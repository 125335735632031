#sticky-icon-bars {
  top: 35%;
  left: 0;
  width: 180px;
}

@media (max-width:1450px) {
  #sticky-icon-bars {
    top: 35%;
  }
}
