@import '../../custom.scss';

#project-heading:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 50%;
  width: 90px;
  height: 4px;
  border-radius: 2px 0 0 2px;
  background-color: $secondary;
  transition: 0.5s;
}

#project-heading::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 90px;
  height: 4px;
  border-radius: 0 2px 2px 0;
  background-color: $secondary;
  transition: 0.5s;
}