#blog-container {
  top: 0;
  left: 0;
}

#blog-title {
  top: 45%;
}

#tsparticles {
  height: 85%;
}

@media (max-width: 767px) {
  h1 {
    font-size: 20px;
  }

  #tsparticles {
    height: 70%;
  }
}